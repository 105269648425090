import "./index.scss";

import PropTypes from "prop-types";

import CopyButton from "common/core/copy_button";

const CX = "ApiSettingsValue";

function ApiSettingsValue(props) {
  const { value } = props;

  return (
    <div className={CX}>
      <p data-automation-id="api-key-value" className={`${CX}--text`}>
        {value}
      </p>
      <CopyButton className={`${CX}--icon`} value={value} iconOnly buttonSize="large" />
    </div>
  );
}

ApiSettingsValue.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ApiSettingsValue;

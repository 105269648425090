import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import {
  HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN,
  HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN,
  IDENTIFY_TRANSACTIONS,
} from "constants/feature_gates";
import {
  SIMPLE_NOTARIZATION_CONFIG,
  NOTARIZATION_CONFIG,
  SIGN_CONFIG,
  VERIFY_CONFIG,
  IDENTIFY_CONFIG,
} from "common/transaction_creation/v3/config";
import { useProofRequirementsEnabled, useSignTransactionsEnabled } from "util/feature_detection";
import { useFeatureFlag } from "common/feature_gating";
import PopoutMenu from "common/core/popout_menu";
import { SidebarActionButton } from "common/sidebar";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import {
  ESIGN_TRANSACTION_TYPE,
  IDENTIFY_TRANSACTION_TYPE,
  VERIFY_TRANSACTION_TYPE,
} from "constants/transaction";
import { DashboardDropdownIcon } from "common/dashboard/dropdown";
import { TRANSACTION_CTA_MESSAGES } from "common/dashboard/constants";
import { useCreateBusinessTransaction } from "common/transactions/create/hooks/use-create-business-transaction";
import { NewBadge } from "common/core/announcements";

type TransactionPopoutMenuProps = {
  handleImportLoop?: () => void;
  orgIsActive?: boolean;
  notaryProfile: Parameters<typeof useProofRequirementsEnabled>[0];
  showVerifyOption?: boolean;
  canCreateSimpleNotarization?: boolean;
  recipient?: NonNullable<Parameters<typeof useCreateBusinessTransaction>[0]>["recipient"];
};

// TODO remove in BIZ-7063
const DEPRECATED_MESSAGES = defineMessages({
  notarizeText: {
    id: "c5cf37da-caad-404c-b4dd-448c0cac4fb9",
    defaultMessage: "Notarization Request",
  },
  notarizeSubText: {
    id: "863a896f-e559-407c-99ab-daf6c9b7250c",
    defaultMessage: "Remote notarizations for non-closings",
  },
  signText: {
    id: "16461030-cb4d-474f-b189-73da2cfdce6d",
    defaultMessage: "eSignature Request",
  },
  signSubText: {
    id: "876e050d-48e6-4c19-94f9-11752deb37e6",
    defaultMessage: "Digital signatures without identity verification or notarization",
  },
  identifyText: {
    id: "72519b1a-7032-4710-8e5f-f769190014c5",
    defaultMessage: "Identify",
  },
  identifySubText: {
    id: "11d9123c-4ee3-43bc-8637-77cf529e5e0c",
    defaultMessage: "Confirm an identity with automated verification",
  },
  verifyText: {
    id: "2dd98072-7578-4add-b8a3-f6d2013554df",
    defaultMessage: "Verify",
  },
  verifySubText: {
    id: "be336520-12b8-4b1f-9fd1-6280c1a7fac5",
    defaultMessage: "Authenticate an identity with a live agent",
  },
});

function DotLoopPopoutItem({ onClick }: { onClick: () => void }) {
  return (
    <PopoutMenuMultilineItem
      data-automation-id="new-dotloop-transaction"
      onClick={onClick}
      primaryContent={
        <FormattedMessage
          id="775c0030-ba42-49c3-a955-b2306ffbc7cf"
          defaultMessage="Import a Loop"
        />
      }
      secondaryContent={
        <FormattedMessage
          id="7924d6e7-8d45-4f5d-b3f2-f13b997a7a7e"
          defaultMessage="Create a transaction from a Loop"
        />
      }
      iconName="arrow-down-square"
    />
  );
}

export function NewBusinessTransactionMenu({
  handleImportLoop,
  orgIsActive = true,
  notaryProfile,
  showVerifyOption = false,
  canCreateSimpleNotarization = false,
  recipient,
}: TransactionPopoutMenuProps) {
  const intl = useIntl();
  const signTransactionsEnabled = useSignTransactionsEnabled();
  const proofRequirementsEnabled = useProofRequirementsEnabled(notaryProfile);
  const proofTransactionsEnabled = proofRequirementsEnabled && !signTransactionsEnabled;
  const showEsignOption = !useFeatureFlag(HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN);
  const showNotarizationOption = !useFeatureFlag(HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN);
  const showIdentifyOption = useFeatureFlag(IDENTIFY_TRANSACTIONS) && proofRequirementsEnabled;

  const { createTransaction, loading } = useCreateBusinessTransaction({ recipient });

  // If customer doesn't have any available transaction creation options, simply hide the entire popout menu
  if (
    !handleImportLoop &&
    !showNotarizationOption &&
    !proofTransactionsEnabled &&
    !showIdentifyOption &&
    !showVerifyOption &&
    !showEsignOption
  ) {
    return null;
  }
  const messages = signTransactionsEnabled ? TRANSACTION_CTA_MESSAGES : DEPRECATED_MESSAGES;

  return (
    <PopoutMenu
      placement="bottomRight"
      target={
        <SidebarActionButton
          disabled={!orgIsActive}
          isLoading={loading}
          disabledHint={
            !orgIsActive && (
              <FormattedMessage
                id="520842a7-1548-4c1b-b8e7-41a3e637f8ef"
                defaultMessage="You cannot send a new transaction because this organization is disabled."
              />
            )
          }
          disabledHintPlacement="bottom"
          automationId="new-transaction"
        >
          <FormattedMessage
            id="a77d78e3-c8bf-4262-bf07-9d11a129cf0c"
            defaultMessage="Send new transaction"
          />
          <DashboardDropdownIcon />
        </SidebarActionButton>
      }
    >
      {() => (
        <>
          {handleImportLoop && <DotLoopPopoutItem onClick={handleImportLoop} />}
          {showNotarizationOption && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-notarization-transaction"
              onClick={() =>
                createTransaction({
                  configId: canCreateSimpleNotarization
                    ? SIMPLE_NOTARIZATION_CONFIG.id
                    : NOTARIZATION_CONFIG.id,
                })
              }
              primaryContent={intl.formatMessage(messages.notarizeText)}
              secondaryContent={intl.formatMessage(messages.notarizeSubText)}
              iconName="notarize-filled"
              disabled={loading}
            />
          )}
          {showEsignOption && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-esign-transaction"
              onClick={() =>
                createTransaction({
                  transactionType: ESIGN_TRANSACTION_TYPE,
                  configId: SIGN_CONFIG.id,
                })
              }
              primaryContent={intl.formatMessage(messages.signText)}
              secondaryContent={intl.formatMessage(messages.signSubText)}
              iconName="pencil-line"
              disabled={loading}
            />
          )}
          {showIdentifyOption && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-identify-transaction"
              onClick={() =>
                createTransaction({
                  transactionType: IDENTIFY_TRANSACTION_TYPE,
                  configId: IDENTIFY_CONFIG.id,
                })
              }
              primaryContent={intl.formatMessage(messages.identifyText)}
              secondaryContent={intl.formatMessage(messages.identifySubText)}
              iconName="secure"
              disabled={loading}
            />
          )}
          {showVerifyOption && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-verify-transaction"
              onClick={() =>
                createTransaction({
                  transactionType: VERIFY_TRANSACTION_TYPE,
                  configId: VERIFY_CONFIG.id,
                })
              }
              primaryContent={
                <>
                  {intl.formatMessage(messages.verifyText)}
                  <NewBadge />
                </>
              }
              secondaryContent={intl.formatMessage(messages.verifySubText)}
              iconName="video-chat"
              disabled={loading}
            />
          )}
        </>
      )}
    </PopoutMenu>
  );
}

import { memo, useCallback, useEffect, useState, useMemo } from "react";
import {
  Observable,
  Subject,
  merge,
  map,
  distinctUntilChanged,
  type Subscription,
  type BehaviorSubject,
} from "rxjs";
import {
  Pipeline,
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
  ImageFit,
} from "@twilio/video-processors";
// eslint-disable-next-line no-restricted-imports -- type only import
import type {
  LocalAudioTrack,
  LocalVideoTrack,
  VideoProcessor,
  Room,
  NetworkQualityStats,
  NetworkQualityLevel,
  LocalParticipant as TwilioLocalParticipant,
  LocalTrackPublication,
  Participant,
} from "twilio-video";

import type { TwilioClient } from "common/twilio";
import { captureException } from "util/exception";
import { isSafari, isMobileDevice } from "util/support";
import type { Devices } from "common/selected_devices_controller";
import { type MediaError, getMediaErrorNameFromException } from "common/video_conference/exception";
import { NotaryProfileSettingValues } from "graphql_globals";
import { useBehaviorSubject } from "util/rxjs/hooks";
import { IMAGE_URLS } from "common/notary/meeting_background";
import type { NetworkQuality, VideoBackgroundSettings } from "common/video_conference";
import { getVideoDeviceConstraints } from "common/video_conference/audio_video_settings/constraints";
import { useLogger } from "common/logger";

import Track from "./track";

type LocalTrack = LocalVideoTrack | LocalAudioTrack;
type TrackState = null | LocalTrack;
type UseLocalTracksParams = {
  videoBackground?: VideoBackgroundSettings;
  participant: TwilioLocalParticipant;
  publishAudio?: boolean;
  publishVideo?: boolean;
  /** If truthy, this means publish _this_ stream. */
  publishScreenStream?: MediaStream;
  selectedDevices?: Devices;
  twilioClient: TwilioClient;
  onDeviceError?: (errorType: MediaError | null) => void;
  privacyVideoAspectRatio?: boolean;
  onStopScreenShare?: () => void;
  room?: Room;
  priority: LocalTrackPublication["priority"] | null;
};
type Props = UseLocalTracksParams & {
  partyId?: string;
  muted?: boolean;
  setNetworkQualityChangedObservable?: (
    partyId: string,
    qualityChanged$: Observable<NetworkQuality>,
  ) => void;
  hideVideo?: boolean;
  videoAriaLabel?: string;
};
type SharedLocalTrackOptions = {
  shouldPublish: boolean | undefined;
  participant: UseLocalTracksParams["participant"];
  priority: Props["priority"];
  onDeviceError?: (errorType: ReturnType<typeof getMediaErrorNameFromException> | null) => void;
  trackStoppedCb?: () => void;
  room?: Room;
};
type TrackProcessorEmission = null | VideoProcessor;
type LocalTrackOptions = SharedLocalTrackOptions &
  (
    | { constructor: () => Promise<LocalAudioTrack>; trackProcessor$?: never }
    | {
        constructor: () => Promise<LocalVideoTrack>;
        trackProcessor$?: BehaviorSubject<TrackProcessorEmission>;
      }
  );

const CUSTOM_VIDEO_BG_EFFECT_TRIGGER = Symbol("Custom video");
const PARTICIPANT_PARAMS = Object.freeze({
  maxAudioBitrate: 60 * 1024, // 60 kb
  maxVideoBitrate: 240 * 1024, // 240 kb
});
const PROCESSOR_SETTINGS = Object.freeze({
  assetsPath: "/twilio-video-processor-assets",
  debounce: Boolean(isSafari()),
  pipeline: Pipeline.WebGL2,
});
const ADD_PROCESSOR_OPTIONS = {
  inputFrameBufferType: "video",
  outputFrameBufferContextType: "webgl2",
} as const;

function useDeviceErrorCb(
  kind: MediaError["kind"],
  onDeviceError: UseLocalTracksParams["onDeviceError"],
) {
  return useCallback(
    (name: MediaError["name"] | null) => onDeviceError?.(name ? { kind, name } : null),
    [onDeviceError],
  );
}

function useLocalTrack(options: LocalTrackOptions): TrackState {
  const {
    trackStoppedCb,
    participant,
    trackProcessor$,
    shouldPublish,
    constructor,
    room,
    onDeviceError,
    priority,
  } = options;
  const logger = useLogger();
  const isMobile = isMobileDevice();
  const [track, setTrack] = useState<TrackState>(null);
  useEffect(() => {
    let live = true;
    let publishedTrack: LocalTrack | undefined;
    let createdLocalTrack: LocalTrack | undefined | null;
    let processorSub: Subscription | undefined;
    const handleSetTrack = (publication: LocalTrackPublication) => {
      setTrack((publishedTrack = publication.track as LocalTrack));
      if (trackStoppedCb) {
        publishedTrack.addListener("stopped", trackStoppedCb);
      }
    };
    const documentVisibilityListener = async () => {
      if (document.visibilityState === "hidden" && createdLocalTrack) {
        logger.log("App was backgrounded, stoping video track");
        // The app has been backgrounded. So, stop and unpublish your LocalVideoTrack.
        createdLocalTrack.stop();
        room!.localParticipant.unpublishTrack(createdLocalTrack);
      } else if (createdLocalTrack) {
        logger.log("App was foregrounded, starting video track");
        // The app has been foregrounded, So, create and publish a new LocalVideoTrack.
        createdLocalTrack = await constructor();
        const publication = await room!.localParticipant.publishTrack(createdLocalTrack, {
          priority: priority || "standard",
        });
        handleSetTrack(publication);
      } else {
        return null;
      }
    };
    const handleError = (error: Error) => {
      if (!live || (error.name === "TwilioError" && error.message === "Room completed")) {
        // This is a normal thing. The room will complete.
        return null;
      }
      const knownErrorType = getMediaErrorNameFromException(error);
      if (knownErrorType) {
        console.warn(`LocalParticipant device error: ${error.name} - ${error.message}`); // eslint-disable-line no-console
        onDeviceError?.(knownErrorType);
      } else {
        captureException(error);
      }
      return null;
    };
    const createTrack = async () => {
      createdLocalTrack = await (
        constructor() as Promise<Awaited<ReturnType<typeof constructor>>>
      ).catch(handleError);
      processorSub = live
        ? trackProcessor$?.subscribe((processor) => {
            if (createdLocalTrack?.kind !== "video") {
              return;
            } else if (createdLocalTrack.processor) {
              createdLocalTrack.removeProcessor(createdLocalTrack.processor);
            }
            if (processor) {
              createdLocalTrack.addProcessor(processor, ADD_PROCESSOR_OPTIONS);
            }
          })
        : undefined;
      const trackPublication =
        live && createdLocalTrack
          ? await participant.publishTrack(createdLocalTrack).catch(handleError)
          : null;
      if (live && trackPublication) {
        handleSetTrack(trackPublication);
      }
      if (isMobile && room && live && createdLocalTrack?.kind === "video") {
        document.addEventListener("visibilitychange", documentVisibilityListener);
      }
    };

    setTrack(null);
    onDeviceError?.(null);
    if (!shouldPublish) {
      return;
    }
    createTrack();

    return () => {
      live = false;
      processorSub?.unsubscribe();
      processorSub = undefined;
      if (createdLocalTrack) {
        createdLocalTrack.stop();
        if (isMobile && room && createdLocalTrack.kind === "video") {
          document.removeEventListener("visibilitychange", documentVisibilityListener);
        }
      }
      if (publishedTrack) {
        participant.unpublishTrack(publishedTrack);
        trackStoppedCb && publishedTrack.removeListener("stopped", trackStoppedCb);
      }
    };
  }, [shouldPublish, constructor, onDeviceError, participant, priority]);
  return track;
}

async function createVirtualBackgroundProcessor(imageSrc: string) {
  return new VirtualBackgroundProcessor({
    ...PROCESSOR_SETTINGS,
    fitType: ImageFit.Cover,
    backgroundImage: await new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.crossOrigin = "anonymous";
      image.src = imageSrc;
    }),
  });
}

async function createVideoProcessor(background: UseLocalTracksParams["videoBackground"]) {
  if (!background) {
    return null;
  } else if (background.kind === "custom") {
    return createVirtualBackgroundProcessor(background.url);
  }
  switch (background.value) {
    case NotaryProfileSettingValues.PHOTO_BG_PROOF:
    case NotaryProfileSettingValues.PHOTO_BG_OFFICE:
    case NotaryProfileSettingValues.PHOTO_BG_BOOKSHELF:
      return createVirtualBackgroundProcessor(IMAGE_URLS[background.value]);
    case NotaryProfileSettingValues.NO_BLUR:
      return null;
    case NotaryProfileSettingValues.FULL_BLUR:
      return new GaussianBlurBackgroundProcessor({ ...PROCESSOR_SETTINGS, blurFilterRadius: 30 });
    case NotaryProfileSettingValues.PARTIAL_BLUR:
      return new GaussianBlurBackgroundProcessor({ ...PROCESSOR_SETTINGS, blurFilterRadius: 10 });
  }
}

function useVideoTrackProcessor(background: UseLocalTracksParams["videoBackground"]) {
  const videoTrackProcessor$ = useBehaviorSubject<TrackProcessorEmission>(null);
  // We have to be tricky here. The characters of the URL of a custom background might
  // change without the content being different due to the crypto tokens refreshing.
  // Yes, this means if the content of the image really does change, we won't update
  // the background on the video, but this should never happen in practice.
  // If one day we do need to handle this requirement, we can use "stable" urls just
  // like we do in PDF URLs.
  const effectTrigger = !background
    ? null
    : background.kind === "custom"
      ? CUSTOM_VIDEO_BG_EFFECT_TRIGGER
      : background.value;
  useEffect(() => {
    let live = true;
    createVideoProcessor(background).then(async (processor) => {
      if (!processor) {
        videoTrackProcessor$.next(null);
        return;
      }
      await processor.loadModel();
      if (live) {
        videoTrackProcessor$.next(processor);
      }
    });
    return () => {
      live = false;
    };
  }, [effectTrigger]);
  return videoTrackProcessor$;
}

function useLocalPublishedTracks({
  videoBackground,
  participant,
  publishVideo,
  publishScreenStream,
  publishAudio,
  priority,
  selectedDevices,
  twilioClient,
  privacyVideoAspectRatio,
  onDeviceError,
  onStopScreenShare,
  room,
}: UseLocalTracksParams): [TrackState, TrackState, TrackState] {
  const { webcam, microphone } = selectedDevices || {};
  const retryErrors = ["OverconstrainedError", "ConstraintNotSatisfiedError"];

  useEffect(() => {
    const disconnectRoom = () => room?.disconnect();
    participant.setParameters(PARTICIPANT_PARAMS);
    window.addEventListener("beforeunload", disconnectRoom);
    window.addEventListener("pagehide", disconnectRoom);
    return () => {
      window.removeEventListener("beforeunload", disconnectRoom);
      window.removeEventListener("pagehide", disconnectRoom);
      disconnectRoom();
    };
  }, [participant]);

  const createVideoTrack = useCallback(() => {
    return twilioClient
      .createLocalVideoTrack(
        getVideoDeviceConstraints({
          deviceId: webcam,
          privacyVideoAspectRatio,
        }),
      )
      .catch((error: Error) => {
        if ([error.name, error.constructor.name].some((x) => retryErrors.includes(x))) {
          return twilioClient.createLocalVideoTrack();
        }
        throw error;
      });
  }, [twilioClient, webcam]);

  const createScreenTrack = useCallback(() => {
    if (!publishScreenStream) {
      return Promise.reject(new Error("Unexpected construction of null screen stream/track."));
    }
    try {
      const [screenTrack] = publishScreenStream.getVideoTracks();
      const twilioLocalScreenTrack = new twilioClient.LocalVideoTrack(screenTrack, {
        name: "screen",
        logLevel: "info",
      });
      return Promise.resolve(twilioLocalScreenTrack);
    } catch (error) {
      onStopScreenShare?.();
      return Promise.reject(error);
    }
  }, [twilioClient, publishScreenStream, onStopScreenShare]);
  const createAudioTrack = useCallback(
    () =>
      twilioClient
        .createLocalAudioTrack({
          deviceId: microphone || undefined,
          name: "microphone",
        })
        .catch((error: Error) => {
          if ([error.name, error.constructor.name].some((x) => retryErrors.includes(x))) {
            return twilioClient.createLocalAudioTrack();
          }
          throw error;
        }),
    [twilioClient, microphone],
  );

  const handleAudioDeviceError = useDeviceErrorCb("audio", onDeviceError);
  const handleVideoDeviceError = useDeviceErrorCb("video", onDeviceError);

  return [
    useLocalTrack({
      shouldPublish: publishVideo,
      constructor: createVideoTrack,
      trackProcessor$: useVideoTrackProcessor(videoBackground),
      participant,
      priority,
      onDeviceError: handleVideoDeviceError,
      room,
    }),
    useLocalTrack({
      shouldPublish: publishAudio,
      constructor: createAudioTrack,
      participant,
      priority,
      onDeviceError: handleAudioDeviceError,
    }),
    useLocalTrack({
      shouldPublish: Boolean(publishScreenStream),
      constructor: createScreenTrack,
      participant,
      priority,
      onDeviceError: onStopScreenShare,
    }),
  ];
}

function isRenderableTrack(track: TrackState): track is LocalTrack {
  return Boolean(track && track.name !== "screen");
}

export function useNetworkQualityLevelChanged({
  partyId,
  participant,
  setNetworkQualityChangedObservable,
  notifyOnDisconnect,
}: {
  partyId?: string;
  participant: Participant;
  setNetworkQualityChangedObservable?: Props["setNetworkQualityChangedObservable"];
  notifyOnDisconnect?: boolean;
}) {
  const presence$ = useMemo(() => new Subject<null>(), [participant]);
  const networkQualityLevelChanged$ = useMemo(
    () =>
      new Observable<NetworkQualityStats | null>((observer) => {
        observer.next(participant.networkQualityStats);
        const listener = (quality: NetworkQualityLevel, stats: NetworkQualityStats) =>
          observer.next(stats);
        participant.on("networkQualityLevelChanged", listener);
        return () => participant.off("networkQualityLevelChanged", listener);
      }).pipe(
        map((networkQualityStats) => {
          // Bad network quality of remote participant can negatively affect
          // network quality of local participant. This is primarily reflected in
          // high receiving packet loss.
          // To go around this issue we only look at receiving audio/video bandwidth levels
          // http://media.twiliocdn.com/sdk/js/video/releases/1.18.0/docs/NetworkQualityMediaStats.html
          const audioQuality = networkQualityStats?.audio;
          const videoQuality = networkQualityStats?.video;
          if (!audioQuality || !videoQuality) {
            return null;
          }

          const audioRecv = audioQuality.recvStats?.bandwidth?.level ?? null;
          const videoRecv = videoQuality.recvStats?.bandwidth?.level ?? null;
          if (audioRecv === null || videoRecv === null) {
            return null;
          }

          return Math.min(
            audioQuality.send,
            videoQuality.send,
            audioRecv,
            videoRecv,
          ) as NetworkQuality;
        }),
      ),
    [participant],
  );

  useEffect(() => {
    if (partyId && setNetworkQualityChangedObservable) {
      setNetworkQualityChangedObservable(
        partyId,
        merge(presence$, networkQualityLevelChanged$).pipe(distinctUntilChanged()),
      );
      return () => {
        notifyOnDisconnect && presence$.next(null);
      };
    }
  }, [partyId, presence$, networkQualityLevelChanged$, setNetworkQualityChangedObservable]);
}

function LocalParticipant(props: Props) {
  const { selectedDevices, muted, hideVideo, videoAriaLabel } = props;
  const { speaker } = selectedDevices || {};
  const tracks = useLocalPublishedTracks(props);
  useNetworkQualityLevelChanged(props);
  return (
    <div>
      {tracks.filter(isRenderableTrack).map((track) => {
        const { name, kind } = track;
        const isAudio = kind === "audio";
        const isVideo = kind === "video";
        return (
          <Track
            key={name}
            track={track}
            sinkId={isAudio ? speaker : null}
            disabled={isAudio && muted}
            hidden={isVideo && hideVideo}
            ariaLabel={videoAriaLabel}
          />
        );
      })}
    </div>
  );
}

export default memo(LocalParticipant);

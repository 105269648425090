import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { getCurrentDocumentNode, getCurrentPenholderInSignerParties } from "common/meeting/util";
import ProgressIcon from "common/core/progress_icon";

import Styles from "./index.module.scss";
import type {
  BeholderHeader as Meeting,
  BeholderHeader_meetingParticipants as MeetingParticipant,
  BeholderHeader_documentBundle_documents_edges_node_annotationDesignations_edges_node as AnnotationDesignation,
  BeholderHeader_meetingParticipants_SignerParticipant as SignerParticipant,
  BeholderHeader_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "./index_fragment.graphql";

function signerParticipantsPredicate(
  participant: MeetingParticipant,
): participant is SignerParticipant | WitnessParticipant {
  return (
    participant.__typename === "SignerParticipant" ||
    participant.__typename === "WitnessParticipant"
  );
}

function renderSigningAs(activeParticipant: SignerParticipant | WitnessParticipant) {
  return (
    <div
      data-automation-id="signing-as"
      className={Styles.signingAs}
      style={{ background: activeParticipant.colorHex }}
    >
      <FormattedMessage
        id="22014fb1-bbc0-4ca5-9556-a58cf6c58d48"
        description="signingAs"
        defaultMessage="Signing as {name}"
        values={{ name: activeParticipant.fullName }}
      />
    </div>
  );
}

function renderDesignationProgress(countAllActions: number, countCompletedActions: number) {
  return (
    <div data-automation-id="designation-progress" className={Styles.designationProgress}>
      <FormattedMessage
        id="8bc67322-a416-4cc6-9469-b139e422e141"
        description="designationProgress"
        defaultMessage="{countCompletedActions} of {countAllActions} Actions Completed"
        values={{ countCompletedActions, countAllActions }}
      />
      <ProgressIcon
        iconName="pencil-line"
        progress={countCompletedActions}
        total={countAllActions}
      />
    </div>
  );
}

export function getConfig(meeting: Meeting) {
  const activeParticipant = getCurrentPenholderInSignerParties(meeting) as
    | SignerParticipant
    | WitnessParticipant;
  const { documentBundle, meetingParticipants } = meeting;
  const {
    documents: { edges: documentEdges },
  } = documentBundle!;
  const currentDocument = getCurrentDocumentNode(meeting);

  const signerRoleIndices = meetingParticipants
    .filter(signerParticipantsPredicate)
    .map((participant) => participant.signerRole.index);

  const designationGroupMap = documentEdges
    .flatMap((document) => document.node.annotationDesignations.edges)
    .map((designation) => designation.node)
    .reduce((map, designation) => {
      if (designation.designationGroupId) {
        const currentGroup = map.get(designation.designationGroupId);
        if (currentGroup) {
          const nextGroup = currentGroup.concat(designation);
          map.set(designation.designationGroupId, nextGroup);
        } else {
          const group = new Array(designation);
          map.set(designation.designationGroupId, group);
        }
      }
      return map;
    }, new Map<string, AnnotationDesignation[]>());

  const allUngroupedDesignations = documentEdges
    .flatMap((document) => document.node.annotationDesignations.edges)
    .map((designation) => designation.node)
    .filter(
      (designation) =>
        signerRoleIndices.includes(designation.signerRole.index) &&
        !designation.designationGroupId &&
        designation.required &&
        designation.active,
    );

  const allDesignationGroups = documentEdges
    .flatMap((document) => document.node.designationGroups)
    .filter((group) => {
      // This could be calculated in API and on the designationGroup graph type
      // Alternatively, could use the total and unfullfilled counts frome API
      const designationGroupActive = designationGroupMap
        .get(group.id)
        ?.some((designation) => designation.active);
      return (
        group.minimumFulfilled &&
        group.signerRole &&
        signerRoleIndices.includes(group.signerRole.index) &&
        designationGroupActive
      );
    });

  const fulfilledDesignations = allUngroupedDesignations.filter(
    (designation) => designation.fulfilled,
  );
  const fulfilledGroups = allDesignationGroups.filter((group) => !group.numOutstandingFulfillments);

  return {
    activeParticipant,
    currentDocument,
    countAllActions: allUngroupedDesignations.length + allDesignationGroups.length,
    countCompletedActions: fulfilledDesignations.length + fulfilledGroups.length,
  };
}

function BeholderHeader({ meeting }: { meeting: Meeting }) {
  const { activeParticipant, currentDocument, countAllActions, countCompletedActions } =
    getConfig(meeting);
  const { meetingParticipants, documentBundle } = meeting;
  const { name } = currentDocument;
  const { edges: docEdges } = documentBundle!.documents;
  const lockedDocs = docEdges.filter((e) => e.node.locked);
  return (
    <div className={Styles.beholderHeader}>
      <div className={Styles.mainStatus}>
        <h2 data-automation-id="current-document-name">{name}</h2>
        {countAllActions > 0 && renderDesignationProgress(countAllActions, countCompletedActions)}
      </div>
      <h3 data-automation-id="document-status" className={Styles.documentStatus}>
        <FormattedMessage
          id="8b9e6486-37c5-4a69-b53f-67523f6dbc35"
          description="signerDocumentProgress"
          defaultMessage="{completedDocs} of {totalDocs} documents completed"
          values={{ completedDocs: lockedDocs.length, totalDocs: docEdges.length }}
        />
      </h3>
      {meetingParticipants.length > 1 &&
        !documentBundle?.batchSigning &&
        renderSigningAs(activeParticipant)}
    </div>
  );
}

export default memo(BeholderHeader);

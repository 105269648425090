import "./index.scss";

import type { ReactNode } from "react";

import AppAlertBanner from "common/banners/app_alert_banner";
import { ScreenClassProvider } from "common/core/responsive";
import NotificationCenter from "common/core/notification_center";
import { useDocumentTitle } from "util/document_title";
import { useFavicon } from "util/html";
import { BrowserSupportWarning } from "common/support/device";
import ErrorModal from "common/error_modal";
import { isMicrosoftEdge, isMobileDevice } from "util/support";
import APPS from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import AssetPreloader from "common/app/asset_preloader";
import { LoggerProvider } from "common/logger";

import LocationAnalytics from "./location_analytics";
import Timeout from "../authentication/timeout";

type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  mobileSupported?: boolean;
};

function isDeviceAllowed(mobileSupported: boolean | undefined): boolean {
  if (isMobileDevice() && !mobileSupported) {
    return false;
  }

  // We don't support notary on edge (would otherwise pass the other tests).
  if (CURRENT_PORTAL === APPS.NOTARY && isMicrosoftEdge()) {
    return false;
  }

  // We always allow edge through since some older versions fail feature detection
  // but the feature detection is mostly for meeting, which we don't allow anyway.
  if (isMicrosoftEdge()) {
    return true;
  }

  // If all the other special casing fails, we just trust.
  return true;
}

function App({ children, isAuthenticated, mobileSupported }: Props) {
  useDocumentTitle(CURRENT_PORTAL);
  useFavicon();
  return (
    <LoggerProvider>
      <ScreenClassProvider>
        <AppAlertBanner />
        {isDeviceAllowed(mobileSupported) ? children : <BrowserSupportWarning />}
        <NotificationCenter />
        <AssetPreloader />
        <ErrorModal />
        <LocationAnalytics />
        {isAuthenticated && <Timeout />}
      </ScreenClassProvider>
    </LoggerProvider>
  );
}

export default App;

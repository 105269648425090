import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import { IconButton } from "common/core/button/icon_button";
import { useCopy } from "util/clipboard";
import Button, { type NotarizeButtonProps } from "common/core/button";

type CopyProps = {
  /** the text you want to copy */
  value: string;
  className?: string;
  /** removes text from button, displays as the copy icon only. Default is false */
  iconOnly?: boolean;
  /** removes icon from button, displays as text only. Default is false */
  noIcon?: boolean;
  /** overrides the copy text */
  children?: ReactNode;
};

type ButtonProps = Pick<
  NotarizeButtonProps,
  "buttonColor" | "variant" | "buttonSize" | "automationId"
>;

export type Props = ButtonProps & CopyProps;

function CopyButton(props: Props) {
  const { value, iconOnly, variant, buttonColor, buttonSize, className, children, noIcon } = props;

  const { copy, recentlyCopied } = useCopy();

  return (
    <>
      {iconOnly ? (
        <IconButton
          label={
            <FormattedMessage
              id="4a2d9798-4e3f-4dff-96ae-f9408ea12ac9"
              defaultMessage="Copy to clipboard"
            />
          }
          className={className}
          name={recentlyCopied ? "tick" : "copy"}
          onClick={() => copy(value)}
          variant={variant ?? "tertiary"}
          buttonColor={buttonColor ?? "dark"}
          buttonSize={buttonSize ?? "condensed"}
        />
      ) : (
        <Button
          className={className}
          variant={variant ?? "tertiary"}
          buttonColor={buttonColor ?? "dark"}
          buttonSize={buttonSize ?? "condensed"}
          withIcon={
            noIcon
              ? undefined
              : {
                  name: recentlyCopied ? "tick" : "copy",
                  placement: "left",
                }
          }
          onClick={() => copy(value)}
        >
          {recentlyCopied ? (
            <FormattedMessage id="1700975d-7865-4696-af5d-2539b685fccf" defaultMessage="Copied!" />
          ) : (
            (children ?? (
              <FormattedMessage id="3b733960-416d-4a45-85ad-20d0b45e3905" defaultMessage="Copy" />
            ))
          )}
        </Button>
      )}
    </>
  );
}

export default CopyButton;

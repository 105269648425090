import type { ComponentProps } from "react";
import classnames from "classnames";

import Link, { type AnchorShapeProps } from "common/core/link";
import SmallLoader from "common/core/loading_indicator/small";

import Styles from "./index.module.scss";
import Icon from "../icon";
import type { NotarizeButtonProps } from ".";

type LinkProps = Omit<
  ComponentProps<typeof Link>,
  "secondary" | "white" | "black" | "underlined" | "loading" | "overrideStyles"
> &
  AnchorShapeProps;

type ButtonProps = Pick<
  NotarizeButtonProps,
  "buttonColor" | "variant" | "buttonSize" | "fullwidth" | "automationId" | "withIcon" | "isLoading"
>;

export type ButtonStyledLinkProps = ButtonProps & LinkProps;

export function ButtonStyledLink({
  buttonColor,
  variant,
  buttonSize = "medium",
  fullwidth,
  className,
  withIcon,
  isLoading,
  children,
  ...props
}: ButtonStyledLinkProps) {
  const cx = classnames(
    Styles.button,
    className,
    buttonColor && Styles[buttonColor],
    variant && Styles[variant],
    Styles[buttonSize],
    fullwidth && Styles.fullWidth,
    isLoading && Styles.loading,
  );

  const loader =
    variant !== "primary" && buttonColor === "danger" ? (
      <SmallLoader color="red" />
    ) : variant !== "primary" && buttonColor === "dark" ? (
      <SmallLoader color="gray" />
    ) : (variant === "primary" && buttonColor !== "light") ||
      (variant !== "primary" && buttonColor === "light") ? (
      <SmallLoader color="white" />
    ) : (
      <SmallLoader color="blue" />
    );

  return (
    <Link
      isLinkButton={variant === "tertiary"}
      underlined={false}
      className={cx}
      overrideStyles
      {...props}
    >
      {isLoading && <div className={Styles.loaderContainer}>{loader}</div>}
      <div className={classnames(Styles.buttonChildren, withIcon && Styles.withIcon)}>
        {withIcon?.placement === "left" && <Icon name={withIcon.name} />}
        <span className={Styles.buttonChildren}>{children}</span>
        {withIcon?.placement === "right" && <Icon name={withIcon.name} />}
      </div>
    </Link>
  );
}

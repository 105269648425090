import { type ReactNode } from "react";

import { DetailsLayout } from "common/details/tokens";
import type { OrganizationTypeEnum } from "graphql_globals";

import { type OrganizationTransactionDetailsHeader as OrganizationTransaction } from "../header/index_fragment.graphql";
import { TransactionDetailsHeader } from "../header";

type TransactionDetailsWrapperProps = {
  children: ReactNode;
  className?: string;
  closeRoute: string;
  transaction: OrganizationTransaction;
  transactionDetailsTabs: ReactNode;
  transactionDetailsActions?: ReactNode;
  showActionErrorMessage?: boolean;
  viewerOrganization?: { organizationType: OrganizationTypeEnum } | undefined | null;
  refetch: () => Promise<unknown>;
};

/**
 * @description
 * This is a component that wraps the transaction details and handles its styles and layout.
 */
export function TransactionDetailsWrapper({
  children,
  className,
  transaction,
  transactionDetailsTabs,
  transactionDetailsActions,
  showActionErrorMessage,
  viewerOrganization,
  refetch,
}: TransactionDetailsWrapperProps) {
  return (
    <DetailsLayout.Container className={className}>
      <TransactionDetailsHeader
        transaction={transaction}
        transactionDetailsActions={transactionDetailsActions}
        showActionErrorMessage={showActionErrorMessage}
        viewerOrganization={viewerOrganization}
        refetch={refetch}
      />
      {transactionDetailsTabs && <DetailsLayout.Row>{transactionDetailsTabs}</DetailsLayout.Row>}
      <DetailsLayout.Body>
        <DetailsLayout.Row>{children}</DetailsLayout.Row>
      </DetailsLayout.Body>
    </DetailsLayout.Container>
  );
}

import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import classNames from "classnames";

import Button from "common/core/button";
import { useQuery } from "util/graphql/query";
import LoadingIndicator from "common/core/loading_indicator";
import EmptyView from "common/dashboard/empty_view/empty";
import { Hr } from "common/core/horizontal_rule";
import { Card, CardSection } from "common/core/card";
import { DashboardContent, DashboardTitle } from "common/dashboard";
import Link from "common/core/link";
import { Badge } from "common/core/badge";
import { Heading, Paragraph } from "common/core/typography";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import WorkflowModal from "common/modals/workflow_modal";
import { formatDate } from "util/date";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import { usePermissions } from "common/core/current_user_role";
import { useContextOrParamsOrgId } from "util/organization";
import CopyButton from "common/core/copy_button";

import OrganizationDomains, {
  type OrganizationDomains_organization_Organization_domainVerifications as DomainItemType,
} from "./organization_domains.query.graphql";
import Styles from "./index.module.scss";
import DeleteDomainVerification from "./delete_verification.mutation.graphql";
import {
  useVerifyDomainMutation,
  VerifiedDomainActionsButton,
} from "./verify_domain/verify_domain";
import { DOMAIN_VERIFICATION_INFO_URL } from "./common/common";
import { CheatUpdateVerificationButton } from "./verify_domain/cheat/update_verification";

const MESSAGES = defineMessages({
  copyRecord: {
    id: "63178af9-a250-40ed-992a-205acb98d526",
    defaultMessage: "Copy {recordType} record",
  },
  verifyNow: {
    id: "e242a463-8b84-4a12-bfdc-19ee4c25d736",
    defaultMessage: "Verify now",
  },
  verifyNowDescription: {
    id: "bcf109f0-e33f-47f8-80ed-b248384a5dfe",
    defaultMessage: "Click “Verify now” to run a verification check",
  },
  deleteButton: {
    id: "115531f1-8172-486c-b936-5549012ad9de",
    defaultMessage: "Delete",
  },
  deleteDomain: {
    id: "6461b1ee-11cb-4f25-a799-fd31d2459e0d",
    defaultMessage: "delete {domainName} domain",
  },
  deleteSuccessMessage: {
    id: "359890f6-3bf2-43be-9b31-aba34f4b567f",
    defaultMessage: "The domain has been deleted.",
  },
  unknownError: {
    id: "dc281631-9f0b-4e4f-9628-7ec764577b68",
    defaultMessage: "Unknown error. Please contact customer support if this issue persists.",
  },
  lastVerified: {
    id: "50debb0b-0322-4d09-ad4a-b917843048ed",
    defaultMessage: "Last verified on {date}",
  },
  lastChecked: {
    id: "c0957615-9b48-40ad-b8c6-208033d60ad3",
    defaultMessage: "Last checked on {date}",
  },
  recordType: {
    id: "9dd71230-cd0b-46a3-a5c8-a58cb3ec57b7",
    defaultMessage: "{recordType} record",
  },
  deleteModalTitle: {
    id: "0a7f1482-0124-4fff-b264-fd13625414dd",
    defaultMessage: "Are you sure you want to delete {domain} from your domains?",
  },
  deleteModalDescription: {
    id: "f357ec79-6715-4cc7-8a40-f67c316dbd43",
    defaultMessage:
      "You will still be able to attempt to claim this domain in the future, but you’ll need to start the process over.",
  },
  cancel: {
    id: "c6b09b10-ade1-43e7-8eb0-f09c51564a94",
    defaultMessage: "Cancel",
  },
  deleteDomainButton: {
    id: "85e66b09-3263-4b7d-8fc7-c35dc1c4e479",
    defaultMessage: "Delete domain",
  },
  detailsAndPolicies: {
    id: "0c44dafa-bdea-499b-a375-998e0789d39f",
    defaultMessage: "Details and policies",
  },
});

function DomainVerificationTile({ domainItem }: { domainItem: DomainItemType }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const [deleteDomainLoading, setDeleteDomainLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { verifyDomain, verifyDomainLoading } = useVerifyDomainMutation(domainItem);

  const recordContent = `${domainItem.recordName}=${domainItem.recordContent}`;
  const domainName = domainItem.domain.domain;

  const deleteDomainVerification = useMutation(DeleteDomainVerification);
  const deleteDomain = useCallback(() => {
    setDeleteDomainLoading(true);
    return deleteDomainVerification({
      variables: {
        input: {
          domainVerificationId: domainItem.id,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          message: intl.formatMessage(MESSAGES.deleteSuccessMessage),
          position: "topCenter",
        });
      })
      .catch((error) => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(MESSAGES.unknownError),
          position: "topCenter",
        });
        captureException(error);
      })
      .finally(() => {
        setDeleteDomainLoading(false);
        setShowDeleteModal(false);
      });
  }, [domainItem]);

  return (
    <Card fullWidth automationId={`${domainName}-card`}>
      <CardSection>
        <div className={Styles.domainCardTop}>
          <div>
            <div>
              <Heading
                className={Styles.domainHeading}
                level="h2"
                textStyle="headingFive"
                data-automation-id={`${domainName}-title`}
                data-id={domainItem.id}
              >
                {domainName}
              </Heading>
              {domainItem.verified ? (
                <Badge kind="success" withIcon="success" data-automation-id={`${domainName}-badge`}>
                  <FormattedMessage
                    id="507be630-6a08-4bb2-90b8-c3e4000bf65c"
                    defaultMessage="Verified"
                  />
                </Badge>
              ) : (
                <Badge kind="infoSubtle" withIcon="info" data-automation-id={`${domainName}-badge`}>
                  <FormattedMessage
                    id="3129836b-6014-4afd-94d1-fdcddff0d416"
                    defaultMessage="Unverified"
                  />
                </Badge>
              )}
            </div>
            <div
              className={Styles.domainVerifiedAt}
              data-automation-id={`${domainName}-last-checked`}
            >
              {domainItem.verified && domainItem.lastVerifiedAt
                ? intl.formatMessage(MESSAGES.lastVerified, {
                    date: formatDate(domainItem.lastVerifiedAt),
                  })
                : domainItem.lastCheckedAt
                  ? intl.formatMessage(MESSAGES.lastChecked, {
                      date: formatDate(domainItem.lastCheckedAt),
                    })
                  : intl.formatMessage(MESSAGES.verifyNowDescription)}
            </div>
          </div>

          {domainItem.verified ? (
            <div className={Styles.domainButtonGroup}>
              <Button
                variant="secondary"
                buttonColor="action"
                onClick={() => {
                  navigate(`${domainName}/policies`);
                }}
                automationId={`${domainName}-details-button`}
              >
                {intl.formatMessage(MESSAGES.detailsAndPolicies)}
              </Button>
              <VerifiedDomainActionsButton buttonType="kebab" domainItem={domainItem} />
            </div>
          ) : (
            <div className={Styles.domainButtonGroup}>
              {!isAdminPortal && (
                <Button
                  variant="tertiary"
                  buttonColor="danger"
                  onClick={() => setShowDeleteModal(true)}
                  automationId={`${domainName}-delete-domain`}
                >
                  {intl.formatMessage(MESSAGES.deleteButton)}
                </Button>
              )}
              {isAdminPortal && hasPermissionFor("editDomainsVerification") && (
                <CheatUpdateVerificationButton domainItemId={domainItem.id} />
              )}
              <CopyButton
                variant="secondary"
                buttonColor="action"
                value={recordContent}
                automationId={`${domainName}-copy-record-button`}
                buttonSize="medium"
              >
                {intl.formatMessage(MESSAGES.copyRecord, { recordType: domainItem.recordType })}
              </CopyButton>
              <Button
                variant="primary"
                buttonColor="action"
                isLoading={verifyDomainLoading}
                onClick={() => {
                  verifyDomain();
                }}
                automationId={`${domainName}-verify`}
              >
                {intl.formatMessage(MESSAGES.verifyNow)}
              </Button>
            </div>
          )}
        </div>
      </CardSection>
      {showDeleteModal && (
        <WorkflowModal
          autoFocus
          positionTop
          title={intl.formatMessage(MESSAGES.deleteModalTitle, { domain: domainName })}
          buttons={[
            <Button
              key="cancel"
              buttonColor="dark"
              variant="tertiary"
              onClick={() => setShowDeleteModal(false)}
            >
              {intl.formatMessage(MESSAGES.cancel)}
            </Button>,
            <Button
              key="delete-domain"
              buttonColor="danger"
              variant="primary"
              isLoading={deleteDomainLoading}
              onClick={deleteDomain}
              automationId={"delete-domain-button"}
            >
              {intl.formatMessage(MESSAGES.deleteDomainButton)}
            </Button>,
          ]}
          footerSeparator={false}
        >
          {intl.formatMessage(MESSAGES.deleteModalDescription)}
        </WorkflowModal>
      )}
    </Card>
  );
}

export function DomainVerification() {
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const navigate = useNavigate();
  const activeOrgId = useContextOrParamsOrgId();
  const { data, loading } = useQuery(OrganizationDomains, {
    variables: { organizationId: activeOrgId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (data?.organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${data?.organization?.__typename}.`);
  }

  return (
    <div className={classNames({ [Styles.keystoneDashboardContent]: isAdminPortal })}>
      <DashboardContent>
        <DashboardTitle
          title={
            <FormattedMessage id="dbc4d97f-c2ad-44b9-abc3-6ac792ae08d1" defaultMessage="Domains" />
          }
          description={
            !isAdminPortal && (
              <>
                <FormattedMessage
                  id="30052abb-da19-4094-ade7-9e858b2dd78e"
                  defaultMessage="Verify your domains to set authentication policies and permissions for your domain users in Proof."
                />
                <br />
                <FormattedMessage
                  id="94e9af0d-97c0-4d7c-9838-877723ae1b23"
                  defaultMessage="<link>Learn more</link> about the domain verification process."
                  values={{
                    link: (text) => <Link href={DOMAIN_VERIFICATION_INFO_URL}>{text}</Link>,
                  }}
                />
              </>
            )
          }
          buttons={
            !isAdminPortal && (
              <Button
                key="claim-new-domain"
                variant={data.organization.domainVerifications.length ? "secondary" : "primary"}
                buttonColor="action"
                onClick={() => {
                  navigate("claim-domain");
                }}
                automationId={"claim-new-domain"}
              >
                <FormattedMessage
                  id="0b469543-b556-46d9-afd4-2be360bdd938"
                  defaultMessage="Claim new domain"
                />
              </Button>
            )
          }
        />
        <Hr className={Styles.hr} />
        {data.organization.domainVerifications.length === 0 ? (
          <EmptyView
            title={{ text: "No saved domains", headingLevel: "h4", headingStyle: "headingFour" }}
          >
            {!isAdminPortal && (
              <>
                <Paragraph>
                  <FormattedMessage
                    id="ea2aecee-210d-42dd-a53a-421b1afde734"
                    defaultMessage="Click “Claim new domain” and follow the steps to verify your domain."
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="eb4df8be-7629-44aa-8604-cdbf7c727d2f"
                    defaultMessage="Once you begin the process, you’ll see a list of your domains and their verification statuses here."
                  />
                </Paragraph>
              </>
            )}
          </EmptyView>
        ) : (
          <>
            {data.organization.domainVerifications.map((item) => (
              <DomainVerificationTile domainItem={item} key={item.recordContent} />
            ))}
          </>
        )}
      </DashboardContent>
    </div>
  );
}

import "./header.scss";

import type { ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";

import CopyButton from "common/core/copy_button";
import { IconButton } from "common/core/button/icon_button";

type Props = {
  title: string;
  actionButton?: ReactNode;
  onBack?: () => void;
  canCopy?: boolean;
};

const MESSAGES = defineMessages({
  goBack: {
    id: "52c235aa-6de9-474d-939c-b0cf5a3ae988",
    defaultMessage: "Go back",
  },
});

export default function AdminExperimentsHeader({ title, actionButton, onBack, canCopy }: Props) {
  const intl = useIntl();
  return (
    <div className="AdminExperimentsHeader">
      <div className="AdminExperimentsHeader--title">
        {onBack && (
          <IconButton
            name="arrow-left"
            variant="secondary"
            buttonColor="dark"
            buttonSize="condensed"
            onClick={onBack}
            className="AdminExperimentsHeader--title--icon"
            automationId="go-back-icon"
            label={intl.formatMessage(MESSAGES.goBack)}
          />
        )}
        <p className="AdminExperimentsHeader--title--text">{title}</p>
        {canCopy && <CopyButton iconOnly className="ml-100" value={title} />}
      </div>
      {actionButton}
    </div>
  );
}

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import { formatMB } from "util/number";
import { segmentTrack } from "util/segment";
import arrayToSentence from "util/array_to_sentence";
import { pushNotification } from "common/core/notification_center/actions";
import { CONTROL } from "common/experiments";
import { getAssignmentAndTrack } from "util/experiments_instance";
import { VALID_DOCS_SUPPORT_URL } from "constants/document";
import { MAX_FILE_SIZE_BYTES } from "constants/document_bundle";
import { EVENT } from "constants/analytics";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";

const MAX_BYTES = formatMB(MAX_FILE_SIZE_BYTES, 0);
const WARNING_ON_DOCX_UPLOAD = "warning_on_docx_upload";

function getDocxWarningMessage() {
  return (
    <FormattedMessage
      id="7619b7eb-7830-4d19-a989-e4b13afaf208"
      defaultMessage="We noticed you uploaded a Word document. Sometimes Word documents can have formatting problems when you upload them to the web. Take a look at your uploaded document. If it looks right, great. If the formatting seems off, you may want to save the file as a PDF and re-upload."
    />
  );
}
/**
 * @description Public constant of file extensions mapped to MIME types supported by the uploader.
 */
export const DOCUMENT_MIMETYPES = Object.freeze({
  PDF: "application/pdf",
  ZIP: "application/zip",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ODT: "application/vnd.oasis.opendocument.text",
  XML: "application/xml",
  ANY: "any",
});

export function lookupMimeTypeFromName(name) {
  const lastIndexOfDot = name.lastIndexOf(".");
  // fix for case where file name does not have an extension (eSign Consent Form)
  if (lastIndexOfDot === -1) {
    return DOCUMENT_MIMETYPES.PDF;
  }
  const fileExtension = name.slice(lastIndexOfDot + 1);
  return DOCUMENT_MIMETYPES[fileExtension.toUpperCase()];
}

/**
 * @description Public constant of file extensions supported in an array
 */
export const SUPPORTED_FILE_EXTENSIONS = Object.keys(DOCUMENT_MIMETYPES);

/**
 * @description Private function for this module that returns array
 * for an input's accept= prop
 */
export function getMimeTypes(supportedFileExtensions) {
  return supportedFileExtensions.map((ext) => DOCUMENT_MIMETYPES[ext]);
}

function trackViewList() {
  segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_VIEW_ACCEPTED_DOCUMENTS);
}

/**
 * @description Private function for this module that returns an
 * internationalized message about 1st file type as preferred.
 */
export function getPreferredFileMessage(supportedFileExtensions, showAcceptedDocuments = true) {
  const supportedList = arrayToSentence(supportedFileExtensions.slice(0, 1));
  const viewLink = (
    <Link onClick={trackViewList} href={VALID_DOCS_SUPPORT_URL} automationId="accepted-docs">
      <FormattedMessage id="065d4d90-87ae-427a-8e10-dd19b50099c7" defaultMessage="View a list" />
    </Link>
  );
  return (
    <FormattedMessage
      id="43c3ecfe-2ef8-43bf-99fb-61fab3552e44"
      defaultMessage="{supportedList} files preferred. Others may be supported. Size limit is {maxBytes} MB. {showAcceptedDocuments, select, true{{viewLink} of documents accepted.} other{}}"
      values={{ maxBytes: MAX_BYTES, supportedList, viewLink, showAcceptedDocuments }}
    />
  );
}

/**
 * @description Private function for this uploader module for visual styling of a document row.
 *
 * @param {string} mimeType
 *
 * @returns {object} Each property represents a visual style of the given `docType`:
 *   @property {string} fileExtension The file type's extension string.
 *   @property {string} documentIconName The file type's icon name.
 */
export function mimeTypeVisualStyles(docType) {
  switch (docType) {
    case DOCUMENT_MIMETYPES.PDF:
      return Object.freeze({ fileExtension: "pdf", documentIconName: "document-lines" });
    case DOCUMENT_MIMETYPES.ODT:
      return Object.freeze({ fileExtension: "odt", documentIconName: "document-lines" });
    case DOCUMENT_MIMETYPES.DOCX:
      return Object.freeze({ fileExtension: "docx", documentIconName: "document-lines" });
    case DOCUMENT_MIMETYPES.ZIP:
      return Object.freeze({ fileExtension: "zip", documentIconName: "document-lines" });
    case DOCUMENT_MIMETYPES.XML:
    case DOCUMENT_MIMETYPES.ANY:
    default:
      return Object.freeze({ documentIconName: "document-blank" });
  }
}

export function isDocX(filename) {
  return filename.toLowerCase().endsWith("docx");
}

export function softWarningOnDocxUpload() {
  getAssignmentAndTrack(WARNING_ON_DOCX_UPLOAD, { context: "softWarningOnDocxUpload" }).then(
    (assignment) => {
      if (assignment !== CONTROL) {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: getDocxWarningMessage(),
          duration: 0,
          subtype: NOTIFICATION_SUBTYPES.WARNING,
        });
      }
    },
  );
}
/**
 * @description Private for this module PropType for a single row.
 */
export const DOCUMENT_PROPTYPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  processingState: PropTypes.string.isRequired,
  mimeType: PropTypes.oneOf(Object.values(DOCUMENT_MIMETYPES)),
  notarizationRequired: PropTypes.bool.isRequired,
  vaulted: PropTypes.bool,
  esign: PropTypes.bool,
  signerCanAnnotate: PropTypes.bool.isRequired,
  witnessRequired: PropTypes.bool.isRequired,
  hidden: PropTypes.bool,
  isEnote: PropTypes.bool,
  isConsentForm: PropTypes.bool,
  s3OriginalAsset: PropTypes.shape({
    url: PropTypes.string,
  }),
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  metadata: PropTypes.shape({
    pagesTotal: PropTypes.number,
  }),
});

/* eslint-disable no-console */
import {
  createContext,
  useContext,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  type ReactNode,
} from "react";
import SumoLogger from "sumo-logger";

import Env from "config/environment";
import { useViewer } from "util/viewer_wrapper";
import { browserReportProperties } from "util/browser_report";
import type { UserRole } from "graphql_globals";

import { cspLoggerCallback } from "./util";
import { useLatestCallback } from "../../util/hooks/use-latest-callback";

export type LoggerMethods = {
  log: (...args: unknown[]) => void;
  warn: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
};

type LoggerContextType = LoggerMethods | null;

const LoggerContext = createContext<LoggerContextType>(null);

type LoggerProviderProps = {
  children: ReactNode;
};

export function stringify(consoleArg: unknown) {
  if (consoleArg instanceof Object) {
    try {
      return JSON.stringify(consoleArg);
    } catch {
      return JSON.stringify(consoleArg, Object.getOwnPropertyNames(consoleArg));
    }
  }
  return consoleArg;
}

function formatLog({
  type,
  userId,
  userRole,
  args,
}: {
  type: "log" | "warn" | "error";
  userId?: string;
  userRole: UserRole | null;
  args: unknown[];
}) {
  const formatedArgs = args.map(stringify).join(", ");
  return `[${userId}] [${userRole}] [WEB] ${type}: ${formatedArgs}`;
}

function getOptions() {
  return { url: window.location.toString() };
}

/**
 * LoggerProvider initializes the SumoLogger for application-wide logging and provides
 * logging methods (log, warn, error) to all child components through React Context.
 *
 * The provider automatically:
 * - Configures SumoLogger with the monitoring endpoint from environment
 * - Includes user ID and role information in logs
 * - Sets up browser event listeners for flushing logs
 * - Handles cleanup on unmount
 * - Patches console methods to redirect through the logger
 */
export const LoggerProvider = ({ children }: LoggerProviderProps) => {
  const { viewer } = useViewer();
  const userId = viewer.user?.id;
  const [userRole] = viewer.user?.roles || [];
  const endpoint = Env.monitoringEndpoint;

  const sumoLoggerRef = useRef<SumoLogger | null>(null);
  const originalConsoleRef = useRef<{
    log: typeof console.log;
    warn: typeof console.warn;
    error: typeof console.error;
  }>({
    log: console.log,
    warn: console.warn,
    error: console.error,
  });

  const logToSumo = useCallback(
    (type: "log" | "warn" | "error", ...args: unknown[]) => {
      if (sumoLoggerRef.current) {
        sumoLoggerRef.current.log(formatLog({ type, userId, userRole, args }), getOptions());
      }
    },
    [userId, userRole],
  );

  const createLogMethod = useCallback(
    (type: "log" | "warn" | "error") => {
      return (...args: unknown[]) => {
        originalConsoleRef.current[type](...args);
        logToSumo(type, ...args);
      };
    },
    [logToSumo],
  );

  const createPatchedMethod = useCallback(
    (type: "log" | "warn" | "error") => {
      return function (this: typeof console, ...args: unknown[]) {
        originalConsoleRef.current[type].apply(this, args);

        if ((type === "log" && args.includes("Sending hi_arturo")) || args.length === 0) {
          return;
        }

        logToSumo(type, ...args);
      };
    },
    [logToSumo],
  );

  useLayoutEffect(() => {
    if (!endpoint) {
      return;
    }

    const { browserTabId } = browserReportProperties();
    const sessionKey = userId ? `${userId}-${browserTabId}` : browserTabId;

    sumoLoggerRef.current = new SumoLogger({
      endpoint,
      interval: 10_000,
      sessionKey,
      sendErrors: true,
    });

    console.log = createPatchedMethod("log");
    console.warn = createPatchedMethod("warn");
    console.error = createPatchedMethod("error");

    const flush = () => {
      sumoLoggerRef.current?.flushLogs();
    };

    window.addEventListener("beforeunload", flush);

    return () => {
      console.log = originalConsoleRef.current.log;
      console.warn = originalConsoleRef.current.warn;
      console.error = originalConsoleRef.current.error;

      window.removeEventListener("beforeunload", flush);
      flush();
      sumoLoggerRef.current = null;
    };
  }, [userId, endpoint, createPatchedMethod]);

  useLayoutEffect(() => {
    window.addEventListener("securitypolicyviolation", cspLoggerCallback);
    return () => {
      window.removeEventListener("securitypolicyviolation", cspLoggerCallback);
    };
  }, []);

  const stableCreateLogMethod = useLatestCallback(createLogMethod);

  const logger = useMemo<LoggerMethods>(() => {
    return {
      log: stableCreateLogMethod("log"),
      warn: stableCreateLogMethod("warn"),
      error: stableCreateLogMethod("error"),
    };
  }, []);

  return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>;
};

/**
 * Custom hook to use the logger in any component or hook
 * @returns {LoggerMethods} Logger methods: log, warn, error
 * @throws {Error} When used outside a LoggerProvider
 */
export const useLogger = (): LoggerMethods => {
  const logger = useContext(LoggerContext);

  if (!logger) {
    throw new Error("useLogger must be used within a LoggerProvider.");
  }

  return logger;
};

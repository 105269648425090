import { useRef, useCallback } from "react";

import { useIsomorphicLayoutEffect } from "util/hooks/use-isomorphic-layout-effect";

function useLatestCallback(callback: undefined): () => undefined;
function useLatestCallback<T extends unknown[], R>(callback: (...args: T) => R): (...args: T) => R;
function useLatestCallback<T extends unknown[], R>(
  callback: ((...args: T) => R) | undefined,
): (...args: T) => R | undefined;

/**
 * A hook that returns a callback that always has access to the latest values
 * without triggering re-renders when dependencies change.
 *
 * This implementation supports both defined and optional callbacks.
 * - If you pass a defined callback, you get a defined callback back
 * - If you pass an optional callback, you get a no-op
 *
 * @template Args The tuple type representing the callback's parameters
 * @template Return The return type of the callback
 * @param callback The function to maintain the latest reference to (can be undefined)
 * @returns A stable callback
 */
function useLatestCallback<T extends unknown[], R>(
  callback: ((...args: T) => R) | undefined,
): (...args: T) => R | undefined {
  const callbackRef = useRef<((...args: T) => R) | undefined>(callback);

  useIsomorphicLayoutEffect(() => {
    callbackRef.current = callback;
  });

  return useCallback((...args: T) => {
    if (typeof callbackRef.current === "function") {
      return callbackRef.current(...args);
    }
    return undefined;
  }, []);
}

export { useLatestCallback };

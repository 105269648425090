/* eslint-disable no-console */
import { CURRENT_PORTAL } from "constants/app_subdomains";

const IS_KEYSTONE_PORTAL = CURRENT_PORTAL === "admin";
const DOCUMENT_META_POLICY = window.document.head
  .querySelector("meta[http-equiv='Content-Security-Policy']")
  ?.getAttribute("content");
const VIOLATION_REPORT_PROPS: (keyof SecurityPolicyViolationEvent)[] = [
  "blockedURI",
  "columnNumber",
  "documentURI",
  "lineNumber",
  "referrer",
  "sample",
  "sourceFile",
  "violatedDirective",
];

export function cspLoggerCallback(event: SecurityPolicyViolationEvent) {
  const { violatedDirective, sourceFile } = event;
  if (violatedDirective !== "script-src" && violatedDirective !== "frame-src") {
    return; // Cut down on extra logging for policies we didn't specify
  }
  if (IS_KEYSTONE_PORTAL && sourceFile.startsWith("https://www.googletagmanager.com")) {
    return; // Noisy and ignorable violation
  }
  const unmodifiedEntry = ["isModifiedCSP", DOCUMENT_META_POLICY !== event.originalPolicy];
  const violationEntries = VIOLATION_REPORT_PROPS.flatMap((prop) => {
    const value = event[prop];
    return value || value === 0 ? [[prop, value as unknown]] : [];
  }).concat([unmodifiedEntry]);
  console.error("[CSP Violation]:", Object.fromEntries(violationEntries));
}
